import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import HomeHeroImageBgNew from "../components/sections/hero/HomeHeroImageBgNew"
import HomeBenefitsSectionNew from "../components/sections/HomeBenefitsSectionNew"
import HomeIntroSectionNew from "../components/sections/HomeIntroSectionNew"
import HomeCardSection from "../components/sections/HomeCardSection"
import HomePromoSection from "../components/sections/HomePromoSection"
import HomePriceSection from "../components/sections/HomePriceSection"
import HomeFeaturesSection from "../components/sections/HomeFeaturesSection"
import HomeContactSection from "../components/sections/HomeContactSection"
import HomeHiringSection from "../components/sections/HomeHiringSection"

const IndexPage = () => (
  <HomeContainer>
    <HeadData isHome />

    <HomeHeroImageBgNew />

    <HomeIntroSectionNew />

    <HomeBenefitsSectionNew />

    <HomeCardSection />

    <HomePromoSection />

    <HomePriceSection />

    <HomeFeaturesSection />

    <HomeContactSection />

    <HomeHiringSection />

  </HomeContainer>
)

export default IndexPage