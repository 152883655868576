import React from 'react'

import ClockIcon from '../../assets/clock.svg'
import WifiIcon from '../../assets/wifi.svg'
import MobileIcon from '../../assets/mobile.svg'

const cardContainer = 'flex flex-col items-center m-6'
const cardTitle = 'text-2xl text-ff-blue-1 uppercase font-bold text-white text-center mb-2'
const cardDesc = 'text-lg text-ff-blue-2 text-white text-center'

const HomeBenefitsSectionNew = () => {
  return (
    <div id="fresh-finds" className="bg-ff-blue-10">
      <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <h2 className="text-5xl md:text-6xl text-white font-bold uppercase leading-tight">
              Fresh Finds at Fresh Folds
            </h2>

            <p className="text-xl md:text-2xl text-ff-blue-2 font-medium leading-relaxed mt-4 mb-4">
              With our eco-friendly, high-efficiency machines you wont be here long, but while you are we want you to feel comfortable. Enjoy all these features and more.
            </p>

            <div className='w-11/12 xl:max-w-screen-2xl grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto mt-16'>
              <div className='w-full'>
                <div className={cardContainer}>
                  <div className='w-48 h-48 mb-6'>
                    <ClockIcon className='w-full h-full' />
                  </div>
                  <h2 className={cardTitle}>Quick Dry Machines</h2>
                  <p className={cardDesc}>Our 450 G-Force high-speed machines minimize drying time.</p>
                </div>
              </div>

              <div className='w-full'>
                <div className={cardContainer}>
                  <div className='w-48 h-48 mb-6'>
                    <WifiIcon className='w-full h-full' />
                  </div>
                  <h2 className={cardTitle}>Free WiFi</h2>
                  <p className={cardDesc}>Happy browsing! Enjoy free wifi while you wait.</p>
                </div>
              </div>

              <div className='w-full'>
                <div className={cardContainer}>
                  <div className='w-48 h-48 mb-6'>
                    <MobileIcon className='w-full h-full' />
                  </div>
                  <h2 className={cardTitle}>Mobile Alerts</h2>
                  <p className={cardDesc}>Get text alerts when your washer or dryer are done.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeBenefitsSectionNew