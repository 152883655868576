import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const HomeIntroSectionNew = () => {
  return (
    <div id='about' className="relative">
      <div className="w-11/12 md:w-full 2xl:max-w-screen-xl mx-auto pt-24 pb-0">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <h2 className="text-5xl md:text-6xl font-bold uppercase leading-tight">
              Get Fresh
            </h2>
            <p className="text-xl md:text-2xl leading-relaxed mt-4 mb-4">
              Fresh Folds laundry is everything you want in a laundromat and more. Get your laundry done fast — really fast — with our state-of-the-art Electrolux washers and dryers. We have it all — free WiFi, free parking, comfortable seating, laundry attendants, and more. Don’t feel like laundry? No problem, drop off your loads and we’ll wash and fold it for you.
            </p>

            <p className="text-xl md:text-2xl leading-relaxed mt-4 mb-4">We accept all major credit/debit cards, EBT, Google Pay and Apple Pay.</p>
          </div>
        </div>
      </div>

      <div id="locations" className='pt-1'>
        <div className="w-11/12 mx-auto">
          <h2 className="text-5xl md:text-6xl mt-24 font-bold uppercase text-center leading-tight">
            Locations
          </h2>

          <div className='w-11/12 grid grid-cols-1 xl:grid-cols-2 gap-8 mx-auto'>
            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                1601 E 72nd St., <br/>Tacoma, WA 98404
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0'>
                  <a
                    href='https://maps.app.goo.gl/45S6dY4Nva4vFYpy8'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                  <a
                    href='tel:253-507-4633'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-transparent hover:bg-ff-blue-9 text-ff-blue-8 hover:text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Call 253-507-4633
                  </a>
                </div>

                <div className='mt-12'>
                  <a href='https://maps.app.goo.gl/45S6dY4Nva4vFYpy8' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map.png"
                      width={610}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                2310 Mildred St., Suite 126, <br/>University Place, WA 98467
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0'>
                  <a
                    href='https://maps.app.goo.gl/Ku7sRUDEY1pxiDWcA'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                  <a
                    href='tel:253-503-0929'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-transparent hover:bg-ff-blue-9 text-ff-blue-8 hover:text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Call 253-503-0929
                  </a>
                </div>

                <div className='mt-12'>
                  <a href='https://maps.app.goo.gl/Ku7sRUDEY1pxiDWcA' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map-2.png"
                      width={608}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                14620 Pacific Ave S, <br/>Parkland, WA 98444
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0'>
                  <a
                    href='https://goo.gl/maps/4eP9M9PtFKujrGhN9'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                  <a
                    href='tel:253-302-3036'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-transparent hover:bg-ff-blue-9 text-ff-blue-8 hover:text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Call 253-302-3036
                  </a>
                </div>

                <div className='mt-12'>
                  <a href='https://goo.gl/maps/4eP9M9PtFKujrGhN9' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map-3-now-open.png"
                      width={610}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                2901 S 38th St, <br/>Tacoma, WA 98409
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <div className='flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0'>
                  <a
                    href='https://maps.app.goo.gl/YeQ8rWjgCzFiwUgX8'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                </div>

                <div className='mt-12'>
                  <a href='https://maps.app.goo.gl/YeQ8rWjgCzFiwUgX8' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map-4-coming-soon.png"
                      width={610}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto  pt-0 pb-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <p className="text-xl md:text-3xl font-medium mb-4">8am - 9pm 7 days a week</p>

            <p className="text-xl md:text-3xl font-medium mb-4">last wash at 7:30pm</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroSectionNew